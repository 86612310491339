import { PrimaryButton } from "@/components/shared/Button";
import { colors } from "@/styles/global.styles";
import { OdienceEvent } from "@/utils/hooks/useDirectorAuthentication";
import { useState } from "react";
import { handleRequestToJoinEvent } from "../helpers/EventDetailsUtils";
import GuestListPopup from "./GuestListPopup";

type GuestListProps = {
  objEvent: OdienceEvent;
  onShowProvisioning: VoidFunction;
};

type GuestListStatusProps = {
  message: string;
  color?: string;
};

const GuestListStatus = ({ message, color }: GuestListStatusProps) => (
  <div
    css={{
      display: "flex",
      gap: "0.5em",
      color: color,
      alignItems: "center",
    }}
  >
    <img
      src="/odience/web_client/add_item_to_list.svg"
      alt="Guest List"
      css={{
        width: "1.6em",
        height: "1.6em",
        color: color,
      }}
    />
    <span>{message}</span>
  </div>
);

const GuestList = ({ objEvent, onShowProvisioning }: GuestListProps) => {
  const [showRequestToJoinPopup, setShowRequestToJoinPopup] = useState(false);
  if (!objEvent.invitations_only) return null;

  return (
    <>
      {!objEvent.invitation_requested && !objEvent.invitation_accepted && (
        <div
          css={{
            display: "flex",
            gap: "2vw",
            alignItems: "center",
            width: "80%",
            justifyContent: "center",
          }}
        >
          <GuestListStatus message="Guest List Required" />
          <PrimaryButton
            onClick={() => setShowRequestToJoinPopup(true)}
            css={{ width: "7em" }}
          >
            Join List
          </PrimaryButton>
        </div>
      )}

      {objEvent.invitation_requested && !objEvent.invitation_accepted && (
        <GuestListStatus
          message="Your Guest List request is pending.."
          color={colors.secondaryTextColor}
        />
      )}

      {objEvent.invitation_accepted && (
        <GuestListStatus
          message="You're on the Guest List!"
          color={colors.secondaryAccentColor}
        />
      )}
      {showRequestToJoinPopup && (
        <GuestListPopup
          objEvent={objEvent}
          handleRequestToJoinEvent={handleRequestToJoinEvent}
          handleShowRequestToJoinPopup={setShowRequestToJoinPopup}
          onShowProvisioning={onShowProvisioning}
        />
      )}
    </>
  );
};

export default GuestList;
