import { colors } from "@/styles/global.styles";
import { directorUrl } from "@/utils";
import { ls } from "@/utils/helpers/localstorage";
import { useToast } from "@/utils/helpers/toastManager";
import { handleAsync } from "@/utils/helpers/Utils";
import { OdienceEvent } from "@/utils/hooks/useDirectorAuthentication";
import { css } from "@emotion/react";
import toast from "react-hot-toast";

export function getEventEndDate(startTime: number, duration: number): Date {
  const startTimeMs = startTime * 1000;
  const endTimeMs = startTimeMs + duration * 1000;
  const endTime = new Date(endTimeMs);

  return endTime;
}

export function formatDuration(duration: number): string {
  const days = Math.floor(duration / (24 * 3600));
  const remainingSeconds = duration % (24 * 3600);
  const hours = Math.floor(remainingSeconds / 3600);
  const minutes = Math.floor((remainingSeconds % 3600) / 60);

  let formattedString = "";
  if (days > 0) {
    formattedString += `${days}d `;
  }
  if (hours > 0) {
    formattedString += `${hours}h `;
  }
  if (minutes > 0) {
    formattedString += `${minutes}m`;
  }

  return formattedString.trim();
}

export function formatEventDate(timestamp: number) {
  const eventDate = new Date(timestamp * 1000);
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfWeek = daysOfWeek[eventDate.getDay()];
  const month = months[eventDate.getMonth()];
  const dayOfMonth = eventDate.getDate();
  const year = eventDate.getFullYear();

  const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}, ${year}`;

  return formattedDate;
}

export interface FullScreenElement extends HTMLElement {
  originalParent?: HTMLElement;
  removeResizeListener?: () => void;
}

function getMediaScale({
  width,
  height,
  maxVW,
  maxVH,
}: {
  width: number;
  height: number;
  maxVW: number;
  maxVH: number;
}): {
  vw: string;
  vh: string;
} {
  maxVW /= 100;
  maxVH /= 100;

  let endingWidth = window.innerWidth;
  let endingHeight = window.innerHeight;
  endingWidth = endingHeight * (width / height);
  endingHeight = endingWidth * (height / width);

  if (height < width) {
    const widthScale = window.innerWidth / endingWidth; /*  * (maxVW / 100) */
    endingWidth *= widthScale;
    endingHeight *= widthScale;
  } else {
    const heightScale =
      window.innerHeight / endingHeight; /*  * (maxVH / 100) */
    endingWidth *= heightScale;
    endingHeight *= heightScale;
  }

  if (endingWidth > window.innerWidth * maxVW) {
    const oldWidth = endingWidth;
    endingWidth = window.innerWidth * maxVW;
    endingHeight *= endingWidth / oldWidth;
  }
  if (endingHeight > window.innerHeight * maxVH) {
    const oldHeight = endingHeight;
    endingHeight = window.innerHeight * maxVH;
    endingWidth *= endingHeight / oldHeight;
  }

  const endingWidthVW = (endingWidth / window.innerWidth) * 100 + "vw";
  const endingWidthVH = (endingHeight / window.innerHeight) * 100 + "vh";

  return { vw: endingWidthVW, vh: endingWidthVH };
}

const MEDIA_MAX_VW = 85;
const MEDIA_MAX_VH = 70;

const resizeListeners = new WeakMap<HTMLElement, () => void>();

export function fullScreenPromoVideo(
  mediaElem: HTMLImageElement | HTMLVideoElement | HTMLAudioElement,
  mediaWrapperElem: FullScreenElement,
  enterFullScreen: boolean
) {
  if (!mediaElem) return;

  const exitFullScreen = () => {
    // Reset the media element to its original state
    const dummy = document.querySelector("[data-place-here]") as HTMLElement;
    if (dummy && mediaWrapperElem.originalParent) {
      dummy.replaceWith(mediaWrapperElem);
      mediaWrapperElem.style.position = "";
      mediaWrapperElem.style.zIndex = "";
      mediaWrapperElem.style.left = "";
      mediaWrapperElem.style.top = "";

      mediaElem.classList.remove("fullscreen");
      (mediaElem as HTMLMediaElement).controls = true;

      const removeListener = resizeListeners.get(mediaElem);
      if (removeListener) {
        removeListener();
        resizeListeners.delete(mediaElem);
      }
    }
  };

  const handleUrlChange = () => {
    exitFullScreen();
    window.removeEventListener("popstate", handleUrlChange);
    window.removeEventListener("hashchange", handleUrlChange);
  };

  if (enterFullScreen) {
    const { top, left, width, height } = mediaElem.getBoundingClientRect();
    mediaElem.style.setProperty("--starting-left", `${left}px`);
    mediaElem.style.setProperty("--starting-top", `${top}px`);
    mediaElem.style.setProperty("--starting-width", `${width}px`);
    mediaElem.style.setProperty("--starting-height", `${height}px`);

    // Help with animating aspect ratio
    mediaElem.style.setProperty(
      "--starting-aspect-ratio",
      mediaElem.style.aspectRatio
    );

    let naturalWidth: number;
    let naturalHeight: number;

    if (mediaElem instanceof HTMLImageElement) {
      naturalWidth = mediaElem.naturalWidth;
      naturalHeight = mediaElem.naturalHeight;
    } else if (mediaElem instanceof HTMLVideoElement) {
      naturalWidth = mediaElem.videoWidth;
      naturalHeight = mediaElem.videoHeight;
    } else {
      naturalWidth = 100;
      naturalHeight = 100;
    }

    mediaElem.style.setProperty("--natural-width", `${naturalWidth}`);
    mediaElem.style.setProperty("--natural-height", `${naturalHeight}`);

    const setEndingSizes = () => {
      // Find the size the final image should be
      // Scales by viewport dimensions
      const { vw, vh } = getMediaScale({
        width: naturalWidth,
        height: naturalHeight,
        maxVW: MEDIA_MAX_VW,
        maxVH: MEDIA_MAX_VH,
      });

      mediaElem.style.setProperty("--ending-width", vw);
      mediaElem.style.setProperty("--ending-height", vh);

      // Center the media element
      const endLeft = `calc(50% - (${vw} / 2))`;
      const endTop = `calc(50% - (${vh} / 2))`;

      mediaElem.style.setProperty("--ending-left", endLeft);
      mediaElem.style.setProperty("--ending-top", endTop);
    };

    setEndingSizes();
    window.addEventListener("resize", setEndingSizes);

    resizeListeners.set(mediaElem, () => {
      window.removeEventListener("resize", setEndingSizes);
    });

    const wrapperParent = mediaWrapperElem.parentElement as FullScreenElement;

    const dummy = document.createElement("div");
    dummy.style.minWidth = `${width}px`;
    dummy.style.minHeight = `${height}px`;
    dummy.setAttribute("data-place-here", "");
    mediaWrapperElem.replaceWith(dummy);

    document.body.appendChild(mediaWrapperElem);
    mediaWrapperElem.style.position = "fixed";
    mediaWrapperElem.style.zIndex = "999";
    mediaWrapperElem.style.left = "var(--ending-left)";
    mediaWrapperElem.style.top = "var(--ending-top)";

    mediaWrapperElem.originalParent = wrapperParent;

    // Make image fullscreen
    mediaElem.classList.add("fullscreen");
    (mediaElem as HTMLMediaElement).controls = false;

    // Add URL change event listeners to exit fullscreen on URL change
    window.addEventListener("popstate", handleUrlChange);
    window.addEventListener("hashchange", handleUrlChange);

    // Use MutationObserver to detect changes in the URL path
    const observer = new MutationObserver(() => {
      handleUrlChange();
      observer.disconnect();
    });

    observer.observe(document, { subtree: true, childList: true });
  } else {
    exitFullScreen();
    window.removeEventListener("popstate", handleUrlChange);
    window.removeEventListener("hashchange", handleUrlChange);
  }
}
export interface CalendarEvent {
  title: string;
  description: string;
  startDate: Date;
  endDate?: Date;
  durationInSeconds?: number;
  location?: string;
}

interface AddToCalendarButtonTooltipProps {
  calendarEvent: CalendarEvent;
}

const CalenderOptionStyles = css({
  backgroundColor: colors.primaryBackground,
  borderRadius: "80px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "15em",
  height: "5em",
  textAlign: "center",
  color: "inherit",
  textDecoration: "none",
  padding: "2em",
  fontSize: "0.7em",
});

export default function AddToCalendarButtonTooltip({
  calendarEvent,
}: AddToCalendarButtonTooltipProps) {
  const { showToast } = useToast();
  const truncatedTitle =
    calendarEvent.title.length > 16
      ? calendarEvent.title.substring(0, 16).replaceAll(/\s+/g, "")
      : calendarEvent.title.replaceAll(/\s+/g, "");

  const handleIcsDownload = (fileName: string, event) => {
    event.preventDefault();
    const icsContent = generateIcsCalendarFile(calendarEvent, fileName);

    // Create a link element
    const link = document.createElement("a");
    link.href = icsContent;
    link.download = fileName;
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up the URL object
    document.body.removeChild(link);
  };

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "2vh",
        color: colors.primaryTextColor,
        position: "absolute",
      }}
    >
      <a
        href={generateGoogleCalendarUrl(calendarEvent)}
        target="_blank"
        rel="noopener noreferrer"
        css={CalenderOptionStyles}
      >
        Google Calendar
      </a>
      <a
        href="#"
        onClick={(event) => {
          showToast("Double click on ICS file in downloads to open");
          handleIcsDownload(`${truncatedTitle}.ics`, event);
        }}
        css={CalenderOptionStyles}
      >
        Apple Calendar
      </a>
      <a
        href="#"
        onClick={(event) => {
          showToast("Double click on ICS file in downloads to open");
          handleIcsDownload(`${truncatedTitle}.ics`, event);
        }}
        css={CalenderOptionStyles}
      >
        Outlook Calendar
      </a>
    </div>
  );
}

function getEndTime(calendarEvent: CalendarEvent) {
  return (calendarEvent.endDate ?? (calendarEvent.durationInSeconds || 0) > 0)
    ? addSecondsToDate(calendarEvent.startDate, calendarEvent.durationInSeconds)
    : undefined;
}

function formatDateForCalendarUrl(date?: Date) {
  return date?.toISOString().replaceAll(/-|:|\.\d+/g, "");
}

function addSecondsToDate(date, seconds) {
  return new Date(date.getTime() + seconds * 1000);
}

function generateGoogleCalendarUrl(calendarEvent: CalendarEvent) {
  const startDate = calendarEvent.startDate;
  let endDate = getEndTime(calendarEvent);

  // If no end date is provided, set it to 1 hour after the start date
  if (!endDate && startDate) {
    endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // Add 1 hour
  }

  const formattedStartDate = formatDateForCalendarUrl(startDate);
  const formattedEndDate = formatDateForCalendarUrl(endDate);

  const encodedUrl = encodeURI(
    [
      "https://www.google.com/calendar/render",
      "?action=TEMPLATE",
      `&text=${calendarEvent.title || ""}`,
      `&dates=${formattedStartDate || ""}`,
      `${formattedEndDate ? "/" + formattedEndDate : ""}`,
      `&details=${`${calendarEvent.description || ""}\n`}`,
      `&location=${calendarEvent.location || ""}`,
      "&sprop=&sprop=name:",
    ].join("")
  );

  return encodedUrl;
}

// Generates ICS for Apple and Outlook calendars
export function generateIcsCalendarFile(
  calendarEvent: CalendarEvent,
  fileName?: string
) {
  const startDate = formatDateForCalendarUrl(calendarEvent.startDate);
  const endDate = formatDateForCalendarUrl(getEndTime(calendarEvent));
  const now = Date.now();

  const icsContent = [
    "BEGIN:VCALENDAR",
    "VERSION:2.0",
    "PRODID:-//Summit//Odience v1.0//EN",
    "BEGIN:VEVENT",
    `UID:${now}`,
    `DTSTAMP:${now}`,
    `DTSTART:${startDate || ""}`,
    endDate ? `DTEND:${endDate}` : "",
    `SUMMARY:${calendarEvent.title || ""}`,
    `DESCRIPTION:${calendarEvent.description || ""}`,
    `LOCATION:${calendarEvent.location || ""}`,
    "END:VEVENT",
    "END:VCALENDAR",
  ].join("\n");

  const encodedUrl = encodeURI(`data:text/calendar;charset=utf8,${icsContent}`);

  return encodedUrl;
}

export const handleRequestToJoinEvent = async (objEvent: OdienceEvent) => {
  const headers = {
    Authorization: `Bearer ${ls.getDirectorToken()}`,
    Accept: "application/json",
  };

  const requestOptions: RequestInit = {
    method: "POST",
    headers: headers,
  };

  const [error, response] = await handleAsync(
    fetch(
      new URL(
        `/api/group/${objEvent.organization_id}/event/requestToJoinEvent/${objEvent.id}`,
        directorUrl
      ),
      requestOptions
    )
  );
  if (error) {
    console.error("Error fetching data:", error);
    toast("Request to join is unavailable");
  } else {
    console.log("response", response);
  }
  return [];
};
