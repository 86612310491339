import ShareIcon from "@/assets/share.svg";
import { paths } from "@/routerPaths";
import { odienceColors } from "@/styles/global.styles";
import { domainUrl } from "@/utils";
import { isMobile } from "@/utils/helpers/Utils";
import { isEmbeddedMode } from "@/utils/hooks/useOdienceOrganization";
import { useRef, useState } from "react";
import { shareOnMobile } from "react-mobile-share";
import { useLocation, useParams } from "react-router-dom";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import { useOnClickOutside } from "usehooks-ts";
import { formatEventDate } from "../helpers/EventDetailsUtils";

const ShareEvent = ({ objEvent }) => {
  const [showSocialShare, setShowSocialShare] = useState(false);
  const location = useLocation();
  const { eventId } = useParams();
  const currentPath = location.pathname;
  const previewOdiencePath = paths.previewOdience;

  const eventName = objEvent?.name;
  const organizationName = objEvent?.organization;
  const eventLink = `https://director.${domainUrl}/o/${eventId}`;
  const eventDate = formatEventDate(objEvent?.date);
  const message = `Please Join ${eventName}'s event on ${eventDate} hosted by ${organizationName}. \nVirtualized: ${eventLink}`;

  const shareRef = useRef(null);

  // @ts-expect-error React 19 type compatibility, nullable ref can be ignored.
  useOnClickOutside(shareRef, () => {
    setShowSocialShare(false);
  });

  const shareOptions = (
    <div
      ref={shareRef}
      css={{
        borderRadius: "1rem",
        height: "5vh",
        justifyContent: "center",
        display: "flex",
        gap: "1.25em",
        alignitems: "center",
        zIndex: 777,
        position: "fixed",
        top:
          currentPath.includes(previewOdiencePath) && isMobile()
            ? "12.5vh"
            : currentPath.includes(previewOdiencePath + "/event/details")
              ? "9vh"
              : "12vh",
        right:
          currentPath.includes(previewOdiencePath) && isMobile()
            ? "1vh"
            : currentPath.includes(previewOdiencePath + "/event/details")
              ? "30vh"
              : "10vw",
        visibility: showSocialShare ? "visible" : "hidden",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        flexDirection: isMobile() ? "column" : "row",
      }}
    >
      <FacebookShareButton className="shareButton ripple" url={eventLink}>
        <FacebookIcon size={50} round={true} />
      </FacebookShareButton>

      <FacebookMessengerShareButton
        className="shareButton ripple"
        appId="1748323695675061"
        url={eventLink}
      >
        <FacebookMessengerIcon size={50} round={true} />
      </FacebookMessengerShareButton>

      <TwitterShareButton className="shareButton ripple" url={message}>
        <XIcon size={50} round={true} />
      </TwitterShareButton>

      <WhatsappShareButton className="shareButton ripple" url={message}>
        <WhatsappIcon size={50} round={true} />
      </WhatsappShareButton>

      <EmailShareButton
        subject={`You have been invited to join ${eventName}'s event on ${eventDate} hosted by ${organizationName}`}
        className="shareButton ripple"
        url={message}
      >
        <EmailIcon size={50} round={true} />
      </EmailShareButton>
    </div>
  );

  const handleShareDivClick = () => {
    setShowSocialShare(!showSocialShare);
  };

  const shareToIframe = () => {
    const shareData = {
      title: message,
      text: message,
      images: [],
      type: "SHARE_TO_IFRAME",
    };
    console.log("SHARE_TO_IFRAME", shareData);
    window.parent.postMessage(shareData, "*");
  };

  return (
    <div>
      <button
        type="button"
        onClick={() => {
          if (isMobile() && !isEmbeddedMode()) {
            shareOnMobile({
              title: message,
              text: message,
              images: [],
            });
          } else if (isMobile() && isEmbeddedMode()) {
            shareToIframe();
          } else {
            handleShareDivClick();
          }
        }}
        css={{
          display: "flex",
          alignItems: "center",
          gap: "0.5em",
          backgroundColor: odienceColors.obsidianGray,
          color: odienceColors.pureWhite,
          padding: isMobile() ? "1em" : "1.8em",
          borderRadius: "50%",
          border: "2px solid rgba(255, 255, 255, 0.15)",
          zIndex: 999,
        }}
      >
        <img
          src={ShareIcon}
          alt="Share"
          css={{ height: "1.6em", width: "1.6em" }}
        />
      </button>
      {shareOptions}
    </div>
  );
};
export default ShareEvent;
